<template>
  <div id="footer">
    <div class="wrapper">
        <div class="top-nav clearfix">
            <div class="nav-list">
                <router-link to="/home" :style="{color: isSelected('/home') ? `#2F63F6` : ''}">首页</router-link>
                <router-link to="/exhibitorCenter" :style="{color: isSelected('/exhibitorCenter') ? `#2F63F6` : ''}">展商中心</router-link>
                <router-link to="/audienceCenter" :style="{color: isSelected('/audienceCenter') ? `#2F63F6` : ''}">观众中心</router-link>
                <router-link to="/conferenceActivities" :style="{color: isSelected('/conferenceActivities') ? `#2F63F6` : ''}">大会活动</router-link>
                <a  target="_blank" href="https://www.cnaifu.com/#/">中艾服供应链</a>
                <router-link to="/news" :style="{color: isSelected('/news') ? `#2F63F6` : ''}">展会新闻</router-link>
                <router-link to="/aboutUs" :style="{color: isSelected('/aboutUs') ? `#2F63F6` : ''}">关于我们</router-link>
            </div>
            <div class="top-right clearfix">
                <div class="zgUs">关注我们</div>
                <div class="MassageBox weixinBox">
                    <img src="../assets/images/wenxinflo.png" alt=""/>
                </div>
                <div class="MassageBox zghBox">
                    <img src="../assets/images/gzhflo.png" alt=""/>
                </div>
                <div class="MassageBox linkBox">
                    <img src="../assets/images/program.png" alt=""/>
                </div>
            </div>
        </div>
        <div class="bottomMasage">
            <ul>
                <li>
                    <h4>联系我们</h4>
                    <p>中艾服（深圳）国际展览有限公司</p>
                    <p>项目合作：陈先生 15361525890 (微信同号)</p>
                    <p>参展资讯：曹女士 13838169924 (微信同号)</p>
                    <p>活动合作：陈先生 18680443980 (微信同号)</p>
                    <p>联系地址：广东省深圳市南山区科技南八路工勘大厦25楼</p>
                </li>
                <li>
                    <h4>指导单位</h4>
                    <p>国家卫生健康委科学技术研究所</p>
                </li>
                <li>
                    <h4>主办单位</h4>
                    <p>世界中医药联合会</p>
                    <p>中国针灸学会</p>
                    <p>中国健康管理协会艾灸健康产业分会</p>
                    <p>广东省生物医学工程学会</p>
                    <p>中艾服(深圳)国际展览有限公司</p>
                </li>
            </ul>
        </div>
        <div class="recordInformation">
            <div class="webCopyright">版权所有 © 中艾服(深圳)国际展览有限公司</div>
            <a href="https://beian.miit.gov.cn/" target="_blank" class="recordNum">粤ICP备2025365744号 <span>安全联盟认证ICP备案：粤ICP备2025365744号</span></a>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EnFooter',
  data() {
    return {
      title: '',
      routerUrl: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.routerUrl = decodeURI(val.path)
        this.title = val.query.title
      },
      immediate: true
    }
  },
  computed: {
    isSelected() {
      return (url) => {
        if (this.routerUrl === '/' || (this.routerUrl === '/activityDetail' && this.title === '首页')) {
          this.routerUrl = '/home'
        } else if (this.routerUrl === '/adsDetail' && this.title === '展商中心') {
          this.routerUrl = '/exhibitorCenter'
        } else if (this.routerUrl === '/activityDetail' && this.title === '大会活动') {
          this.routerUrl = '/conferenceActivities'
        } else if (this.routerUrl === '/newsDetail' && this.title === '展会新闻') {
          this.routerUrl = '/news'
        }
        return this.routerUrl === url
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
// @import "../assets/styles/color";
/* Footer */
#footer {
  background-color: #F7F8FA;
  border-top: 1px solid #F7F8FA;
  .top-nav{
      padding:43px 0 27px;
      border-bottom: 1px solid #E5E6EB;
      min-width: 1280px;
      .nav-list{
        float: left;
        height: 28px;
        a{
            line-height:28px;
            color:#86909C;
            font-size:16px;
            margin-right:40px;
            &:hover{
                color:#2F63F6;
            }
        }
      }
      .top-right{
          float: right;
          width:395px;
          .zgUs{
              line-height:28px;
              color:#1D2129;
              font-size:16px;
              float: left;
          }
          img{
              width:120px;
              height:157px ;
              position: absolute;
              left: 50%;
              margin-left:-60px;
              bottom: 40px;
          }
          .MassageBox{
            width:28px;
            height:28px;
            background-size:100% auto;
            float: left;
            position: relative;
            margin-left: 20px;
            cursor: pointer;
            img{
                display: none;
            }
            &:hover img{
                display: block;
            }
            &.weixinBox{
                background-image: url('/public/weixin.png');
                background-repeat:no-repeat;
            }
            &.zghBox{
                background-image: url('/public/gzh.png');
                background-repeat:no-repeat;
            }
            &.linkBox{
                background-image: url('/public/linkto.png');
                background-repeat:no-repeat;
            }
          }
      }
  }
  .bottomMasage{
    ul{
        border-top: 1px solid #E5E6EB;
        border-bottom: 1px solid #E5E6EB;
        padding:24px 0;
        display: flex;
        li{
            border-right: 1px solid #E5E6EB;
            box-sizing: border-box;
            padding: 0 24px;
            width: 33.33%;
            h4{
                font-size: 16px;
                color: #1D2129;
                line-height: 22px;
                font-weight: bold;
                font-family: PingFang SC, PingFang SC;
            }
            p{
                line-height:20px;
                color:#4E5969;
                font-size: 14px;
                margin-top: 12px;
            }
            p:nth-of-type(1){
                margin-top: 16px;
            }
        }
        li:nth-of-type(1){
            padding-left: 0;
        }
        li:last-of-type{
            padding-right: 0;
            border-right: none;
        }
    }
  }
  .recordInformation{
      line-height:20px;
      color:#86909C;
      font-size:14px;
      padding:24px 0 40px;
      display: flex;
      align-items: center;
      .webCopyright{
        margin-right:24px;
      }
      .recordNum{
          color:#86909C;
          background: url('/public/nationalEmblem.png');
          background-repeat: no-repeat;
          background-size:20px 20px;
          background-position: left center;
          text-indent: 24px;
          span{
              margin-left: 12px;
              display: inline-block;
          }
      }
  }
}
</style>
