import Vue from 'vue'
import App from './App.vue'
import router from './router'// 导入router配置文件
import VueLazyLoad from 'vue-lazyload'
import axios from 'axios'
window.axios = axios

import '@/assets/styles/normalize.css'
import '@/assets/styles/base.scss'
import '@/assets/styles/public.scss'

import { Pagination, Dialog, Image, Statistic} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import * as EnComponents from './components'
import * as filters from '@/utils/filters'

// import Mock from '@/mock'

// Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.use(VueLazyLoad)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(Statistic)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Object.keys(EnComponents).forEach(key => {
  Vue.component(EnComponents[key].name, EnComponents[key])
})

new Vue({
  router,
  render: h => h(App),
  components: { App }
}).$mount('#app')
