import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter) // 使用VueRouter插件

const routes = [ // 定义路由配置
  { path: '/', name: 'Home', mate: { title: '首页' }, component: () => import('@/views/home')},
  { path: '/home', name: 'Home', mate: { title: '首页' }, component: () => import('../views/home')},
  { path: '/aboutUs', name: 'AboutUs', mate: { title: '关于我们' }, component: () => import('../views/aboutUs')},
  { path: '/exhibitorCenter', name: 'ExhibitorCenter', mate: { title: '展商中心' }, component: () => import('../views/exhibitorCenter')},
  { path: '/conferenceActivities', name: 'ConferenceActivities', mate: { title: '大会活动' }, component: () => import('../views/conferenceActivities')},
  { path: '/audienceCenter', name: 'AudienceCenter', mate: { title: '观众中心' }, component: () => import('../views/audienceCenter')},
  { path: '/news', name: 'News', mate: { title: '展会新闻' }, component: () => import('../views/news')},
  { path: '/newsDetail', name: 'NewsDetail', component: () => import('../views/newsDetail')},
  { path: '/activityDetail', name: 'ActivityDetail', component: () => import('../views/activityDetail')},
  { path: '/404', name: 'Page404', component: () => import('../views/404')},
  { path: '/adsDetail', name: 'AdsDetail', component: () => import('../views/adsDetail')}
]

const router = new VueRouter({ // 创建router实例并传递routes配置项
  mode: 'history', // 使用HTML5 History模式
  routes // 简写，等同于routes: routes
})

export default router // 导出router实例，以便在Vue实例中使用
