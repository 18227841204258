<template>
<div>
  <div class="header" v-bind:class="[fixedShow ? 'toBottom' : '']">
    <div class="top-header wrapper">
        <div class="top-header-left">
            <img class="logo" src="../assets/images/logo.png" alt="" />
            <div class="nav-list" >
                <router-link to="/home" :style="{color: isSelected('/home') ? `#2F63F6` : ''}">首页</router-link>
                <router-link to="/exhibitorCenter" :style="{color: isSelected('/exhibitorCenter') ? `#2F63F6` : ''}">展商中心</router-link>
                <router-link to="/audienceCenter" :style="{color: isSelected('/audienceCenter') ? `#2F63F6` : ''}">观众中心</router-link>
                <router-link to="/conferenceActivities" :style="{color: isSelected('/conferenceActivities') ? `#2F63F6` : ''}">大会活动</router-link>
                <a  target="_blank" href="https://www.cnaifu.com/#/">中艾服供应链</a>
                <router-link to="/news" :style="{color: isSelected('/news') ? `#2F63F6` : ''}">展会新闻</router-link>
                <router-link to="/aboutUs" :style="{color: isSelected('/aboutUs') ? `#2F63F6` : ''}">关于我们</router-link>
                <!-- <router-link v-for="(item, index) in navList" :key="index" :to="item.link" :style="{color: isSelected(item.link) ? `#2F63F6` : ''}">{{item.name}}</router-link> -->
            </div>
        </div>
        <div class="sign-up">
            <div class="register-btn" @click="handleShowDialog">参展登记</div>
            <div class="sign-up-btn" @click="handleShowDialog">展商报名</div>
        </div>
    </div>
  </div>
  <el-dialog title="联系我们"  :visible.sync="dialogShow" width="960px">
      <add-weixin></add-weixin>
  </el-dialog>
</div>
</template>

<script>

import AddWeixin from '@/components/addWeixin.vue'

export default {
  name: 'EnHeader',
  components: {
    AddWeixin
  },
  data() {
    return {
      title: '',
      dialogShow: false,
      fixedShow: true,
      routerUrl: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val)
        this.routerUrl = decodeURI(val.path)
        this.title = val.query.title
      },
      immediate: true
    }
  },
  computed: {
    isSelected() {
      return (url) => {
        if (this.routerUrl === '/' || (this.routerUrl === '/activityDetail' && this.title === '首页')) {
          this.routerUrl = '/home'
        } else if (this.routerUrl === '/adsDetail' && this.title === '展商中心') {
          this.routerUrl = '/exhibitorCenter'
        } else if (this.routerUrl === '/activityDetail' && this.title === '大会活动') {
          this.routerUrl = '/conferenceActivities'
        } else if (this.routerUrl === '/newsDetail' && this.title === '展会新闻') {
          this.routerUrl = '/news'
        }
        return this.routerUrl === url
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.timeLineFixedStatus()
      window.addEventListener('scroll', this.timeLineFixedStatus)
    })
  },
  methods: {
    handleShowDialog() {
      this.dialogShow = true
    },
    timeLineFixedStatus() {
      const bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (bodyScrollTop > 0) {
        this.fixedShow = true
      } else {
        this.fixedShow = false
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
  .header {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      border-bottom: 1px solid #fff;
      background-color: rgba(255, 255, 255, 0);
      z-index: 1000;
      transition: .3s;
    &.toBottom{
      background-color: rgba(255, 255, 255, 1);
    }
    .top-header {
      height:74px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top-header-left{
        display: flex;
        align-items: center;
      }
    }
    .headerFixed{
        background-color: #fff;
    }
    .logo{
      width:230px;
      height:40px;
    }
    .nav-list{
        margin-left:24px;
        a{
          width:110px;
          height:46px;
          line-height:46px;
          font-size: 16px;
          color: #86909C;
          text-align: center;
          display: inline-block;
          cursor: pointer;
          &:hover{
              color: #2F63F6;
          }
        }
    }
    .sign-up{
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        .register-btn{
            width: 120px;
            height: 46px;
            color: #2F63F6;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            border: 1px solid #2F63F6;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background-color: rgba(47, 99, 246, .1);
            }
        }
        .sign-up-btn{
            width: 120px;
            height: 46px;
            color: #36B291;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            border: 1px solid #36B291;
            cursor: pointer;
            margin-left: 8px;
            transition: .3s;
            &:hover{
                background-color: rgba(54, 178, 145, .1);
            }
        }
    }
  }
 /deep/ .el-dialog__body{
    border-top: 1px solid #E5E6EB;
    padding-top: 0;
  padding-bottom: 57px;
  }
</style>
