<template>
  <div id="app">
    <router-view />
    <en-footer v-if="routerUrl !== '/404'"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      routerUrl: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.routerUrl = decodeURI(val.path)
      },
      immediate: true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
